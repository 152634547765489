import DateFnsUtils from '@date-io/date-fns';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AttachMoneyOutlinedIcon from '@material-ui/icons/AttachMoneyOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import TooltipComponent from '@material-ui/core/Tooltip';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import clsx from 'clsx';
import matchSorter from 'match-sorter';
import React, { useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import Tooltip from '../../../../components/Tooltip';
import FileUploader from '../../../../components/FileUploader';
import { ThumbsUp } from '../../../../components/Icons';

const filterOptions = (options, { inputValue }) =>
	matchSorter(options, inputValue);

const useStyles = makeStyles((theme) => ({
	expand: {
		transform: 'rotate(0deg)',
		transition: theme.transitions.create('transform', {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: 'rotate(180deg)',
	},
	card: {
		marginBottom: 16,
		backgroundColor: theme.palette.background.default,
	},
	cardContent: {
		padding: 24,
		paddingTop: 0,
	},
}));

const printReqString = () => {
	let base = 'Acceptable formats:';
	let docBase = 'Document';
	let docSize = '- size must not exceed 50mb';
	let docReq = '- .xls, .xlsx, .xlsm, .csv, .doc, .docx, .pdf, .txt';

	return (
		<div style={{ whiteSpace: 'pre-line' }}>
			<Typography variant='body2' color='textPrimary'>
				{base}
			</Typography>
			<Typography style={{ marginTop: 16 }} variant='body2' color='textPrimary'>
				{docBase}
			</Typography>
			<Typography variant='body1' color='textPrimary'>
				{`${docSize}\n${docReq}`}
			</Typography>
		</div>
	);
};

export default function QuotationCard(props) {
	const {
		index,
		errorMessages,
		quotation,
		quotationData,
		setQuotationData,
		disableRemove,
		optionData,
	} = props;
	const classes = useStyles();
	const theme = useTheme();
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 768 });

	const [loading, setLoading] = useState(false);

	const toggleOpen = () => {
		let updatedQuotationData = [...quotationData];
		if (!quotation.open) {
			updatedQuotationData[index].open = true;
		} else {
			updatedQuotationData[index].open = false;
		}
		setQuotationData(updatedQuotationData);
	};

	const handleInputChange = (e, index) => {
		const {
			target: { value, type, name },
		} = e;
		let updatedQuotationData = [...quotationData];
		if (name === 'principalId') {
			let val = optionData.find((principal) => principal._id === value);
			if (val) {
				updatedQuotationData[index].principalId = val._id;
				updatedQuotationData[index].principalName = val.shortName;

				let currLength = updatedQuotationData.filter(
					(quotation) => quotation.principalName === val.shortName
				).length;

				updatedQuotationData[index].quoteName = `Quote ${currLength}`;
			} else {
				updatedQuotationData[index].principalId = '';
				updatedQuotationData[index].principalName = '';
				updatedQuotationData[
					index
				].quoteName = `Quote ${updatedQuotationData.length}`;
			}
		} else {
			updatedQuotationData[index][name] =
				type === 'number' && value !== '' ? parseFloat(value) : value;
		}
		setQuotationData(updatedQuotationData);
	};

	const handleDateChange = (index, target, date) => {
		let updatedQuotationData = [...quotationData];
		updatedQuotationData[index][target] = date;
		setQuotationData(updatedQuotationData);
	};

	const handleFileChange = (e, files) => {
		e.preventDefault();
		let updatedQuotationData = [...quotationData];
		updatedQuotationData[index].fileData = files;
		setQuotationData(updatedQuotationData);
	};

	const handleRecommendationChange = (id) => {
		let updatedQuotationData = [...quotationData];
		updatedQuotationData.forEach((el) =>
			el.id === id
				? (el.recommended = true)
				: ((el.recommended = false), (el.recommendationRemarks = ''))
		);
		setQuotationData(updatedQuotationData);
	};

	const handleRemoveRecommendation = (id) => {
		let updatedQuotationData = [...quotationData];
		updatedQuotationData.forEach(
			(el) =>
				el.id === id &&
				((el.recommended = false), (el.recommendationRemarks = ''))
		);
		setQuotationData(updatedQuotationData);
	};

	const handleRemoveQuotation = (id) => {
		if (quotationData.length === 1) return;
		let updatedQuotationData = [...quotationData].filter((el) => el.id !== id);
		if (updatedQuotationData.length === 1) {
			updatedQuotationData[0].recommended = true;
		}
		setQuotationData(updatedQuotationData);
	};

	return (
		<Card className={classes.card} elevation={0}>
			<CardHeader
				title={
					<>
						<div style={{ display: 'flex', alignItems: 'center' }}>
							<IconButton
								className={clsx(classes.expand, {
									[classes.expandOpen]: quotation.open,
								})}
								style={{ marginRight: 8 }}
								onClick={() => toggleOpen()}
								aria-expanded={quotation.open}
								aria-label='show more'
							>
								<ExpandMoreIcon />
							</IconButton>
							<Typography color='textPrimary' variant='h5'>
								{quotation.principalName
									? `${quotation.principalName} - ${quotation.quoteName}`
									: `[Select Insurer] - ${quotation.quoteName}`}
							</Typography>
							<div style={{ marginLeft: 'auto' }}>
								{quotation.recommended ? (
									<Button
										style={{
											backgroundColor: theme.palette.success.main,
											color: theme.palette.background.default,
										}}
										onClick={() => handleRemoveRecommendation(quotation.id)}
										variant='contained'
										startIcon={
											<ThumbsUp
												style={{ fontSize: 24 }}
												colorcode={theme.palette.primary.contrastText}
											/>
										}
									>
										Recommended
									</Button>
								) : (
									<TooltipComponent
										title='Click on this button to recommend this Quotation to your Client'
										placement='top'
										interactive
										arrow
									>
										<Button
											onClick={
												quotation.recommended
													? () => handleRemoveRecommendation(quotation.id)
													: () => handleRecommendationChange(quotation.id)
											}
											variant='outlined'
											color='primary'
											startIcon={
												<ThumbsUp
													style={{ fontSize: 24 }}
													colorcode={theme.palette.tertiary.main}
												/>
											}
										>
											Recommend
										</Button>
									</TooltipComponent>
								)}

								<Button
									color='primary'
									disabled={disableRemove}
									startIcon={<RemoveCircleOutlineIcon />}
									onClick={() => handleRemoveQuotation(quotation.id)}
								>
									Remove
								</Button>
							</div>
						</div>
					</>
				}
			/>
			<Collapse in={quotation.open} timeout='auto' unmountOnExit>
				<CardContent classes={{ root: classes.cardContent }}>
					<Grid container spacing={4}>
						<Grid item xs={12} sm={6} md={4}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Insurer
							</Typography>
							<FormControl
								style={{ width: '100%' }}
								error={
									!!errorMessages[`quotationData.${props.index}.principalId`]
								}
							>
								<Select
									native
									name='principalId'
									value={quotation.principalId}
									inputProps={{
										style: { color: 'unset' },
									}}
									onChange={(e) => handleInputChange(e, index)}
									variant='filled'
									fullWidth
									autoWidth={true}
									margin='dense'
									style={{ marginTop: 8, marginBottom: 4 }}
								>
									<option key={0} value=''></option>
									{optionData &&
										optionData.map((principal, index) => {
											return (
												<option key={index} value={principal._id}>
													{principal.shortName}
												</option>
											);
										})}
								</Select>
								{!!errorMessages[
									`quotationData.${props.index}.principalId`
								] && (
									<FormHelperText>
										{errorMessages[`quotationData.${props.index}.principalId`]}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Quotation Expiry Date
							</Typography>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									name='expiryDate'
									disableToolbar
									placeholder='DD/MM/YYYY'
									inputVariant='filled'
									format='dd/MM/yyyy'
									openTo='year'
									views={['year', 'month', 'date']}
									margin='dense'
									fullWidth
									value={quotation.expiryDate ? quotation.expiryDate : null}
									autoOk
									variant='inline'
									invalidDateMessage='Invalid Date Format'
									onChange={(date) =>
										handleDateChange(index, 'expiryDate', date)
									}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									error={
										!!errorMessages[`quotationData.${props.index}.expiryDate`]
									}
									helperText={
										errorMessages[`quotationData.${props.index}.expiryDate`] &&
										errorMessages[`quotationData.${props.index}.expiryDate`]
									}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Box width='100%' />
						<Grid item xs={12} sm={6} md={4}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Period of Insurance (From)
							</Typography>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									name='startDate'
									disableToolbar
									placeholder='DD/MM/YYYY'
									inputVariant='filled'
									format='dd/MM/yyyy'
									openTo='year'
									views={['year', 'month', 'date']}
									margin='dense'
									fullWidth
									value={quotation.startDate ? quotation.startDate : null}
									autoOk
									variant='inline'
									invalidDateMessage='Invalid Date Format'
									onChange={(date) =>
										handleDateChange(index, 'startDate', date)
									}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									error={
										!!errorMessages[`quotationData.${props.index}.startDate`]
									}
									helperText={
										errorMessages[`quotationData.${props.index}.startDate`] &&
										errorMessages[`quotationData.${props.index}.startDate`]
									}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Period of Insurance (To)
							</Typography>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									name='endDate'
									disableToolbar
									placeholder='DD/MM/YYYY'
									inputVariant='filled'
									format='dd/MM/yyyy'
									openTo='year'
									views={['year', 'month', 'date']}
									margin='dense'
									fullWidth
									value={quotation.endDate ? quotation.endDate : null}
									autoOk
									variant='inline'
									invalidDateMessage='Invalid Date Format'
									onChange={(date) => handleDateChange(index, 'endDate', date)}
									KeyboardButtonProps={{
										'aria-label': 'change date',
									}}
									error={
										!!errorMessages[`quotationData.${props.index}.endDate`]
									}
									helperText={
										errorMessages[`quotationData.${props.index}.endDate`] &&
										errorMessages[`quotationData.${props.index}.endDate`]
									}
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Box width='100%' />
						<Grid item xs={12} sm={6} md={4}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									style={{ marginRight: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									Total Premium
								</Typography>
								<Tooltip
									content={`Enter Premium inclusive of Goods & Services Tax (GST) if
								applicable. This Premium will be reflected in your Invoice
								to your Client.`}
								/>
							</div>
							<TextField
								name='premiumAmt'
								value={quotation.premiumAmt ?? ''}
								type='number'
								onChange={(e) => handleInputChange(e, index)}
								placeholder='Enter Total Premium'
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<InputAdornment style={{ marginTop: 0 }} position='start'>
											<AttachMoneyOutlinedIcon />
										</InputAdornment>
									),
								}}
								error={
									!!errorMessages[`quotationData.${props.index}.premiumAmt`]
								}
								helperText={
									errorMessages[`quotationData.${props.index}.premiumAmt`] &&
									errorMessages[`quotationData.${props.index}.premiumAmt`]
								}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									style={{ marginRight: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									Sum Insured
								</Typography>
								<Tooltip
									content={`Enter "0" if Sum Insured is available in the
										Quotation/Policy Document`}
								/>
							</div>
							<TextField
								name='sumInsured'
								value={quotation.sumInsured ?? ''}
								type='number'
								onChange={(e) => handleInputChange(e, index)}
								placeholder='Enter Sum Insured'
								variant='filled'
								fullWidth
								margin='dense'
								InputProps={{
									startAdornment: (
										<InputAdornment style={{ marginTop: 0 }} position='start'>
											<AttachMoneyOutlinedIcon />
										</InputAdornment>
									),
								}}
								error={
									!!errorMessages[`quotationData.${props.index}.sumInsured`]
								}
								helperText={
									errorMessages[`quotationData.${props.index}.sumInsured`] &&
									errorMessages[`quotationData.${props.index}.sumInsured`]
								}
							/>
						</Grid>
						<Box width='100%' />
						<Grid item xs={12} md={8}>
							<Typography color='textPrimary' variant='body2' align='left'>
								Notes
							</Typography>
							<TextField
								name='notes'
								value={quotation.notes}
								onChange={(e) => handleInputChange(e, index)}
								placeholder='Enter Notes'
								variant='filled'
								fullWidth
								margin='dense'
								multiline
								minRows={4}
								error={!!errorMessages[`quotationData.${props.index}.notes`]}
								helperText={
									errorMessages[`quotationData.${props.index}.notes`] &&
									errorMessages[`quotationData.${props.index}.notes`]
								}
							/>
						</Grid>
						<Box width='100%' />
						<Grid item xs={12} md={8}>
							<div
								style={{
									display: 'flex',
									alignItems: 'center',
								}}
							>
								<Typography
									style={{ marginRight: 8 }}
									color='textPrimary'
									variant='body2'
									align='left'
								>
									Upload Quotation Document
								</Typography>
								<Tooltip content={printReqString()} />
							</div>
							<div
								style={{
									width: '100%',
									marginTop: 8,
									marginBottom: 4,
								}}
							>
								<FileUploader
									fileLimit={1}
									files={quotation.fileData}
									handleFileChange={handleFileChange}
									formErrorMessage={
										errorMessages[
											`quotationData.${props.index}.quoteDocument`
										] &&
										errorMessages[`quotationData.${props.index}.quoteDocument`]
									}
								/>
							</div>
						</Grid>
						{quotation.recommended && (
							<Grid item xs={12} md={8}>
								<Typography color='textPrimary' variant='body2' align='left'>
									Recommendation Remarks
								</Typography>
								<TextField
									name='recommendationRemarks'
									value={quotation.recommendationRemarks}
									onChange={(e) => handleInputChange(e, index)}
									placeholder='Enter Recommendation Remarks'
									variant='filled'
									fullWidth
									margin='dense'
									multiline
									minRows={4}
									error={
										!!errorMessages[
											`quotationData.${props.index}.recommendationRemarks`
										]
									}
									helperText={
										errorMessages[
											`quotationData.${props.index}.recommendationRemarks`
										] &&
										errorMessages[
											`quotationData.${props.index}.recommendationRemarks`
										]
									}
								/>
							</Grid>
						)}
					</Grid>
				</CardContent>
			</Collapse>
		</Card>
	);
}
